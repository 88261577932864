body {
    margin: 0;
    padding: 0;
    /* overflow: hidden;  */
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it is above other content */
  }
  
  .loader {
    width: 60px;
    display: flex;
    justify-content: space-evenly;
  }
  
  .ball {
    list-style: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .ball:nth-child(1) {
    animation: bounce-1 2.1s ease-in-out infinite;
  }
  
  @keyframes bounce-1 {
    50% {
      transform: translateY(-18px);
    }
  }
  
  .ball:nth-child(2) {
    animation: bounce-2 2.1s ease-in-out 0.3s infinite;
  }
  
  @keyframes bounce-2 {
    50% {
      transform: translateY(-18px);
    }
  }
  
  .ball:nth-child(3) {
    animation: bounce-3 2.1s ease-in-out 0.6s infinite;
  }
  
  @keyframes bounce-3 {
    50% {
      transform: translateY(-18px);
    }
  }
  